<template>
  <page-template>
    <main-block>
      <div class="wide-md mx-auto">
        <block-head title="" back-button></block-head>
        <block-content>
          <h6 class="nk-text-dark fw-500 mt-3">{{$t('Template')}} {{$t('Name')}}</h6>
          <b-input v-model="templateData.name" maxlength="50" id="template_name"></b-input>
          <h6 class="nk-text-dark fw-500 mt-3">{{$t('Information')}}</h6>
          <b-input v-model="templateData.info" maxlength="100" id="template_info"></b-input>
          <h6 class="nk-text-dark fw-500 mt-3">{{$t('Email Subject')}}</h6>
          <ckeditor class="border-light border bg-white px-3" v-model="templateData.subject" :editor="InlineEditor" :config="inlineEditorConfig"></ckeditor>
          <h6 class="nk-text-dark fw-500 mt-3">{{ $t('Email Body')}}</h6>
          <ckeditor class="border-light border bg-white px-3" v-model="templateData.body" :editor="ClassicEditor" :config="classicEditorConfig"></ckeditor>
          <div class="mt-3">
            <ion-spinner v-if="updatingTemplateSpinner"></ion-spinner>
            <nk-button v-else type="primary" v-on:click="F_saveTemplate">{{$t('Save')}} {{$t('Template')}}</nk-button>
          </div>
        </block-content>
      </div>
    </main-block>
  </page-template>
</template>

<script>
import {toastController} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {BlockContent, BlockHead, MainBlock} from "@core/layouts";
import "bootstrap"
import useTemplateFunctions from "./eTemplatesFunctions"
import {useRoute} from "vue-router"
import {useStore} from "vuex"
import CKEditor from "@ckeditor/ckeditor5-vue"
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment"
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PlaceholderPlugin from "ckeditor5-placeholder"
import {Table, TableCaption, TableToolbar} from "@ckeditor/ckeditor5-table/src"
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting"
import {StandardEditingMode, RestrictedEditingMode} from "@ckeditor/ckeditor5-restricted-editing/src"
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle"
import {Image, ImageUpload, ImageToolbar, ImageStyle, ImageResize} from "@ckeditor/ckeditor5-image/src"
import SimpleUploadAdaptor  from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter"
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline"
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote"
import {Indent, IndentBlock} from "@ckeditor/ckeditor5-indent/src"
import {FontColor, FontSize, FontFamily} from "@ckeditor/ckeditor5-font/src"
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed"
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading"
import DOMPurify from "dompurify"
import BInput from "@core/components/bp-form/components/BInput";
import NkButton from "@core/components/button/NkButton";
import {useI18n} from "vue-i18n";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    NkButton,
    BInput,
    BlockHead,
    BlockContent,
    ckeditor: CKEditor.component,
    MainBlock,
  },
  setup() {

    const{t} = useI18n()
    const{ getTemplateData, saveTemplate, templateInitialData } = useTemplateFunctions()
    let templateData = reactive(templateInitialData)

    let showSpinner  = ref(false)
    let updatingTemplateSpinner = ref(false)
    const store = useStore()
    const route = useRoute()

    const F_getTemplateData = (id) => {
      showSpinner.value = true
      getTemplateData(id).then(resp=>{
        if(!resp.success){
          toastController.create({header: t('Error'), message: resp.message, duration: 3500, color: "danger"}).then((t)=>t.present())
        }
        else{
          for(let x in resp.data){
            templateData[x] = resp.data[x]
          }
        }
      })
      .catch(err => {
        let m = err.response ? err.response.status+' '+err.response.statusText : err
        toastController.create({header: t('Error'), message: m, duration: 3500, color: "danger"}).then((t)=>t.present())
      })
      .then(()=> showSpinner.value = false)
    }
    onMounted(()=> {
      if(route.params.id !== 'new'){
        F_getTemplateData(route.params.id)
      }
    })

    const inlineEditorConfig = {
      plugins: [EssentialsPlugin, ParagraphPlugin, PlaceholderPlugin],
      //removePlugins: ['Heading'],
      toolbar: ['placeholder'],
      placeholderProps: {
        types: store.state.general_data.placeholders,
      },
    }
    let classicEditorConfig = reactive({
      plugins: [AlignmentPlugin, EssentialsPlugin, ParagraphPlugin, PlaceholderPlugin, BoldPlugin, ItalicPlugin,
        LinkPlugin, Table, TableCaption, TableToolbar, SourceEditing, ListStyle, Image, ImageUpload, ImageToolbar,
        ImageStyle, ImageResize, SimpleUploadAdaptor, HorizontalLine, BlockQuote, Indent, IndentBlock, FontColor,
        FontSize, FontFamily, HtmlEmbed, HeadingPlugin],
      //removePlugins: ['Heading'],
      toolbar: ['heading', 'bold', 'italic', '|', 'link', 'placeholder', 'alignment', 'undo', 'redo', 'numberedList',
        'bulletedList', 'insertTable', 'sourceEditing', 'imageUpload', 'horizontalLine', 'blockQuote', 'outdent', 'indent',
        'fontSize', 'fontColor', 'fontFamily', 'htmlEmbed'],
      table: {
        contentToolbar: [
          'toggleTableCaption','tableColumn', 'tableRow', 'mergeTableCells',
        ]
      },
      image: {
        toolbar: [
          'imageStyle:block',
          'imageStyle:side',
          '|',
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'linkImage'
        ]
      },
      placeholderProps: {
        types: store.state.general_data.placeholders,
      },
      simpleUpload: {
        uploadUrl: process.env.VUE_APP_API_ENDPOINT+'api/third_party/ckeditor/image_upload',
        headers: {
          Authorization: 'Bearer '+localStorage.getItem('access_token'),
        }
      },
      htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: ( inputHtml ) => {
          // Strip unsafe elements and attributes, e.g.:
          // the `<script>` elements and `on*` attributes.
          const outputHtml = DOMPurify.sanitize( inputHtml );

          return {
            html: outputHtml,
            // true or false depending on whether the sanitizer stripped anything.
            hasChanged: DOMPurify.removed.length
          };
        }
      },
    })
    if(store.state.auth.user_role === 'is-admin'){
      classicEditorConfig.plugins.push(StandardEditingMode)
      classicEditorConfig.toolbar.push('restrictedEditingException')
    }
    else{
      classicEditorConfig.plugins.push(RestrictedEditingMode)
      classicEditorConfig.toolbar.push('restrictedEditing')
    }

    const F_saveTemplate = async () => {
      const t = await toastController.create({header: t('Error'), duration: 3500, color:"danger", position: "top"})
      updatingTemplateSpinner.value = true
      saveTemplate(templateData).then(r=>{
        if(r.success){
          templateData.id = r.data.id;
          t.message = t('thank_you_message.email_template_saved')
          t.color = 'tertiary'
          t.header = t('Success')
        }
        else{
          t.message = r.message
        }
        t.present()
      })
      .catch(er=>{
        t.message = er.response ? er.response.status+' '+er.response.statusText : er
        t.present()
      })
      .then(() => updatingTemplateSpinner.value = false)
    }

    return{
      ClassicEditor,
      classicEditorConfig,
      F_saveTemplate,
      InlineEditor,
      inlineEditorConfig,
      showSpinner,
      templateData,
      updatingTemplateSpinner,
    }
  }
});

</script>

<style>
.ck-editor__main ul, .ck-editor__main ol{
  padding: inherit;
  list-style-position: inside;
}
</style>
